import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging/sw";
import { getToken } from "firebase/messaging";
import axios from "axios";
import { read_cookie } from "sfcookies";
import isTokenExpired from "./services/auth_header";
import { setCookie, getNotificationCookie } from "./services/cookies";

const firebaseConfig = {
  apiKey: "AIzaSyAoSCCBhtBUmmkvYgNoTG_DYlyAHjLcJiY",
  authDomain: "lifesvr-59908.firebaseapp.com",
  projectId: "lifesvr-59908",
  storageBucket: "lifesvr-59908.appspot.com",
  messagingSenderId: "768125017798",
  appId: "1:768125017798:web:71d8cbd368f89434fa41c7",
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const getPermissions = async () => {
  await Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      const notif_token = getNotificationCookie("notification_token");
      if (Object.keys(notif_token).length === 0) {
        get_token();
        
      } else if (notif_token && notif_token.token === "") {
        get_token();
        
      } else {
       
        saveSubscription(notif_token);
      }
    } else {
      unsubscribeUser();
    }
  });
};

let isReloading = false;

const get_token = async() => {

  if('serviceWorker' in navigator){
    navigator.serviceWorker.ready.then(function(registration){
        if(registration.active){
          getToken(messaging, {
            vapidKey: process.env.REACT_APP_VAPID_KEY,
          })
            .then((currentToken) => {
              isReloading=false;
              if (currentToken) {
                saveSubscription({ token: currentToken });
                setCookie(
                  "notification_token",
                  JSON.stringify({ token: currentToken }),
                  365
                );
              }
            })
            .catch((error) => {
              if (!isReloading) {
                isReloading = true;
                window.location.reload();
              }
            });
            isReloading=false;
        }
        else{
          isReloading=false;
        }
    }).catch((e)=>{
      isReloading=false;
      
    })
   
   
  }
  else{
    console.log("No Active Service Worker")
  }
  
 
};

export function unsubscribeUser() {
  saveSubscription({ token: "" });
  setCookie("notification_token", JSON.stringify({ token: "" }, 365));
}

function saveSubscription(token) {
  const auth = read_cookie("auth");
  if (auth?.accessToken && !isTokenExpired()) {
    const data = {
      device_token: token === "" ? "" : token?.token,
      user_id: auth?.id,
      role: auth?.role ? auth?.role.toString() : "",
    };

    const url = "https://api.lifesavr.co.uk/api/auth/save_user_token";
    const headers = {
      "Content-Type": "application/json",
      Authorization: auth?.accessToken,
    };

    axios
      .post(url, data, { headers })
      .then((res) => {
        // console.log("Token: ",data?.device_token)
      })
      .catch((e) => {});
  }
}

